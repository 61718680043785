/* app styles & overrides  */
body,
#root {
  min-height: 100vh;
  overflow: hidden;
}

#root {
  --tw-ring-color: #ef4444;
  background-color: #0f172a;
}

[type='text']:focus,
[type='email']:focus,
[type='url']:focus,
[type='password']:focus,
[type='number']:focus,
[type='date']:focus,
[type='datetime-local']:focus,
[type='month']:focus,
[type='password']:focus,
[type='number']:focus,
[type='date']:focus,
[type='datetime-local']:focus,
[type='month']:focus,
[type='search']:focus,
[type='tel']:focus,
[type='time']:focus,
[type='week']:focus,
[multiple]:focus,
textarea:focus,
select:focus {
  --tw-ring-color: #ef4444;
  border-color: #ef4444;
}

.bg-primary {
  background-color: #142841 !important;
}

.text-red {
  color: #ef4444;
}
.border-red {
  border-color: #ef4444;
}
.ring-red {
  border-color: #ef4444;
}

.h-main {
  height: calc(100vh - 64px);
}

/* custom scrollbar styles */
@media screen and (min-width: 540px) {
  body ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 10px;
    height: 10px;
  }
  body ::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 0;
  }
  body ::-webkit-scrollbar-thumb {
    cursor: pointer;
    border-radius: 5px;
    background-color: rgba(79, 70, 229, 0.5);
    -webkit-transition: color 0.2s ease;
    transition: color 0.2s ease;
  }
  body ::-webkit-scrollbar-thumb:window-inactive {
    background-color: rgba(0, 0, 0, 0.15);
  }
  body ::-webkit-scrollbar-thumb:hover {
    background-color: rgba(79, 70, 229, 0.8);
  }
}

.btn-pulse-click {
  transition-duration: 0.4s;
}

.btn-pulse-click:after {
  content: "";
    display: block;
    position: absolute;
    border-radius: 4em;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: all 0.5s;
    box-shadow: 0 0 10px 40px white;
}

.btn-pulse-click:active:after {
  box-shadow: 0 0 0 0 white;
    position: absolute;
    border-radius: 4em;
    left: 0;
    top: 0;
    opacity: 1;
    transition: 0s;
}

.btn-pulse-click:active {
  top: 1px;
}