.icon-color-primary {
  color: #ece0dd;
}

.icon-color-secondary {
  color: #c32a25;
}

.loader-wrapper {
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%);
}
/* .loader-container {
  display: grid;
  place-items: center;
  position: relative;
} */
.spinning-loader {
  /* position: absolute;
  top: 50%;
  left: 50%; */
  width: 90px;
  height: 90px;
  z-index: 999999999;
  animation: loader-spin infinite 1s linear;
}
.spinning-loader-inner {
  position: absolute;
  top: 33%;
  left: 34%;
  width: 30px;
  height: 30px;
  z-index: 9999999999;
  animation: loader-spin-counter infinite 1s linear;
}

@keyframes loader-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes loader-spin-counter {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}
